import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'password-reset',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
      },
      validationErrors: {},
      isLoading: false,
      isShowMessage: false,
    }
  },
  components: {},
  validations: {
    payload: {
      email: {
        required,
        email,
        maxLength: maxLength(40),
      },
    }
  },
  computed: {
    isDisabled() {
      if (this.payload.email === '') {
        return true
      } else {
        return false
      }
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax',{count: 40}));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {

        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/AUTH_REQUEST_PASSWORD_RESET',
    }),
    resetForm(){
      this.$v.$reset();
      this.payload.email = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        this.resetPassword(this.payload)
          .then(() => {
            this.isShowMessage = true;
            this.resetForm();
          }).catch(error => {
          console.log('error',error.response)
          if(error.response.status === 422){
            this.$set(this.validationErrors, 'email', [error.response.data.data.message]);
          }
        }).finally(() =>{
          this.isLoading = false;
        });
      }
    },
  }
}
